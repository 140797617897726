@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
html,
body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

fieldset {
  border: none;
  outline: 0;
}

fieldset:disabled .MuiSelect-icon {
  display: none;
}

@media print {
  #no-print {
    display: none;
  }
}

